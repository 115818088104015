<template>
  <div class="fluid-container md:py-32 px-10 md:px-44">
    <h1 class="text-white text-3xl pt-10 pb-5 ">Vektkalkulator</h1>
    <p class="md:w-1/2 text-white text-xl pb-3  font-normal">Vektkalkulatoren beregner vekten på et hefte eller limfrest trykksak med eller uten eget omslag. 
</p>
   <p class="md:w-1/2 text-white text-xl pb-6  font-normal ">Dette er nyttig når man skal beregne fraktkostnader.</p>
    <div class="flex justify-between flex-wrap">
      <div class="w-full md:w-1/2">
        <form >
          <label class=" leading-10 font-normal text-lg">ANTALL SIDER TOTALT</label>
          <input
            min="0"    
            maxlength="4"
            oninput="this.value=this.value.slice(0,this.maxLength||0/1); this.value=(this.value  < 0) ? (0) : this.value;"
            v-model.number="numberOfPages"
            type="number"
            id="pages"
            name="pages"
            class=" text-xl bg-white text-black-200 border-3 border-black-100 rounded-none w-full py-4 px-4 block leading-normal outline-none focus:border-green-500"
          >

          <label class=" pt-4 leading-10 font-normal text-lg">ANTALL EKSEMPLARER</label>
          <input
            min="0"
            maxlength="4"
            oninput="this.value=this.value.slice(0,this.maxLength||0/1); this.value=(this.value  < 0) ? (0) : this.value;"
            v-model.number="numberOfUnits"
            type="number"
            id="numunits"
            name="numunits"
            class="text-xl bg-white text-black-200 border-3 border-black-100 rounded-none w-full py-4 px-4 block leading-normal outline-none focus:border-green-500"
          >
          <div class="flex justify-between py-8">
            <div class="w-2/5  flex-col">
               <label class="pt-6 leading-10 overflow-x-hidden  text-lg font-normal">BREDDE I (mm)</label>
          <input
            min="0"
            maxlength="4"
          oninput="this.value=(this.value  < 0) ? (0) : this.value; this.value=this.value > 1000? (1000): this.value"
            v-model.number="width"
            type="number"
            id="width"
            name="width"
            class="text-xl bg-white text-black-200 border-3 border-black-100 rounded-none w-full py-4 px-4 block leading-normal outline-none focus:border-green-500"
          >

            </div>
            <div class="w-2/5   flex-col">
              <label class="pt-6 leading-10 text-lg font-normal">HØYDE I (mm)</label>
          <input
            min="0"
            maxlength="4"
          oninput="this.value=(this.value  < 0) ? (0) : this.value; this.value=this.value > 1000? (1000): this.value"
            v-model.number="height"
            type="number"
            id="height"
            name="height"
            class="bg-white text-xl text-black-200 border-3 border-black-100 rounded-none w-full py-4 px-4 block leading-normal outline-none focus:border-green-500"
          >
            </div>
          </div>
         
          
           
           <label class=" leading-10 pt-4 text-lg font-normal">GRAMVEKT PÅ PAPIR </label>
            <div class="relative mb-10">
              <select
                v-model="weight"
                class="block text-black-200 appearance-none rounded-none w-full bg-white py-4 px-4 pr-8 leading-normal border-3 border-black-100 outline-none focus:border-green-500"
                id="grid-state"
              >
                <option
                  id="weight"
                  v-for="paper in papers"
                  v-bind:key="paper.value"
                  v-bind:value="paper.value"
                >{{ paper.value }}
                </option>
              </select>
              <div
                class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-black-200"
              >
                <svg
                  class="fill-current h-4 w-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path
                    d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                  ></path>
                </svg>
              </div>
            </div>

          
           <div class="flex mt-10 pb-5 v-on">
              <label class="radio-square inline-flex">
                <input
                  type="checkbox"
                  name="radio-colors"
                  id="omslagOption"
                  value="omslagOption"
                  v-model="omslagOption"
                >
                <span class="checkmark-square"></span>
              </label>
              <h2 class="inline-block  text-lg ">Jeg ønsker omslag </h2>
            </div>

            <div v-show="omslagOption">
           <label class=" leading-10 pt-4 text-lg font-normal">GRAMVEKT PÅ PAPIR PÅ OMSLAG</label>
            <div class="relative mb-10">
              <select
                v-model="coverWeight"
                class="block text-black-200 appearance-none rounded-none w-full bg-white py-4 px-4 pr-8 leading-normal border-3 border-black-100 outline-none focus:border-green-500"
                id="grid-state"
              >
                <option
                  id="covers"
                  v-for="cover in covers"
                  v-bind:key="cover.value"
                  v-bind:value="cover.value"
                >{{ cover.value }}
                </option>
              </select>
              <div
                class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-black-200"
              >
                <svg
                  class="fill-current h-4 w-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path
                    d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                  ></path>
                </svg>
              </div>
            </div>
            </div>
        </form>
      </div>
      <div class="w-full pt-10 md:w-2/5 justify-end">
        <div class="flex-col">
          <div class="flex justify-between  bg-grey-800 md:max-w-sm h-auto p-3 lg:px-8 mb-20">
            <div id="clipout" class="flex-col  border border-dashed border-grey-800 p-2">
              <h3 class="text-xl leadiing-none font-normal text-xl">Estimert vekt:</h3>
              <h2  v-if="result < 0" id="result" class="text-3xl font-bold text-green-500 leading-normal"> 0 gr </h2>
              <h2 v-else  v-show="result<1000" class="text-3xl font-bold text-green-500 leading-normal">{{ result }} gr </h2>
              <h2 v-if="result>=1000" class="text-3xl font-bold text-green-500 leading-normal"> {{ resultKg }}</h2>
              <div class="flex-col">
                <div class="font-normal text-xl ">
                  <div class="inline-block">
                    <h3 v-if="coverWeight == 0" id="presult" class="pr-1">{{ numberOfPages }} sider /</h3>
                    <h3 v-else id="presult" class="pr-1">{{ numberOfPagesMinusCover }} sider /</h3>
                  </div>
                  <div class="inline-block font-normal text-xl">
                    <h3 v-if="coverWeight == 0" id="presult_ark" >{{ numberOfSheets }} ark </h3>
                    <h3 v-else id="presult_ark">{{ numberOfSheetsMinusCover }} ark på innmat</h3>
                  </div>
                  <h3 v-if="coverWeight > 0">+ 4 sider / 2 ark omslag</h3>
                </div>

                <!--<h3 id="numsheets">antall ark:{{ numberOfSheets }}</h3>-->
                <h3 v-if="numberOfUnits>1" id="numunits">{{ numberOfUnits }} eksemplarer</h3>
                <h3 v-else id>{{ numberOfUnits }} eksemplar </h3>
                <h3 id="width">{{ this.width }} x {{ this.height }} mm</h3>
              </div>
            </div>

          <!-- <div class="relative tooltip pt-3">
            <button
              @mouseenter="clipOut"
              @mouseleave="leave"
              type="button"
              class=" flex paste-button self-start pt-3 justify-end flex-none"
              v-clipboard:copy="
                displayResult
              "
            >
              <img
                class="p-2"
                @click="changeSvgColor"
                id="copypaste"
                src="../assets/copy-paste.svg"
              >
            </button>
            
             <span class="tooltiptext shadow  absolute p-2 text-grey-800  bg-white border border-grey-500  rounded-md">kopier til utklippstavle</span>
           
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.paste-button:hover {
  filter: brightness(0.7) sepia(1) hue-rotate(149deg) saturate(2);
}
</style>

<script>


export default {
  name: "Main",
  props: {},
  data() {
    return {
      omslagOption: false,
      papers:[
        {value: 90},
        {value: 100 },
        {value: 120 },
        {value: 115 },
        {value: 130 },
        {value: 150 },
        {value: 170 } 
      ],

    covers:[
      { value:115 },
      { value:120 },
      { value:130 },
      { value:150 },
      { value:170 },
      { value:190 },
      { value:200 },
      { value:240 },
      { value:250 },
      { value:300 }

    ],
      numberOfPages: 0,
      numberOfUnits: 1,
      width: 0,
      height: 0,
      weight: 0,
      coverWeight: 0
    };
  },
  methods: {
    changeSvgColor: function() {
      document.querySelector("#copypaste").classList.add("changecolor");
      setTimeout(
        () =>
          document.querySelector("#copypaste").classList.remove("changecolor"),
        500
      );
    },
    clipOut: function() {
      let clip = document.querySelector("#clipout");
      clip.style.border = "1px dashed grey";

      //clip.style.boxShadow = "0 5px 18px 10px black";
    },
    leave: function() {
      let clip = document.querySelector("#clipout");
      clip.style.border = "1px dashed #232323";
      clip.style.backgroundColor = "#232323";
      clip.style.boxShadow = "none";
    }
  },
  computed: {
    numberOfPagesMinusCover: function() {
      if (this.numberOfPages >= 4) {
        return this.numberOfPages - 4;
      } else {
        return 0;
      }
    },
    
    numberOfSheetsMinusCover: function() {
      if (this.numberOfSheets >= 4) {
        return this.numberOfSheets - 2;
      } else {
        return 0;
      }
    },
    


    realPages: function() {
      return " " + Math.round(this.numberOfPages / 2) * 2;
    },
    numberOfSheets: function() {
      return " " + this.realPages / 2 + " " + " ";
    },

    area: function() {
      return (this.width * this.height) / 1000000;
    },
    result: function() {
      if (this.coverWeight == 0 || !this.omslagOption) {
        return (
          " " + (
            (this.realPages * this.numberOfUnits * this.weight * this.area) /
            2
          ).toFixed(0)
        );
      } else {
        return (
          " " +
          (((this.realPages - 4) *
              this.numberOfUnits *
              this.weight *
              this.area) /
              2 +
            (4 * this.area * this.coverWeight * this.numberOfUnits) / 2).toFixed(0) 
          
        );
      }
    },

  resultKg: function(){
    return (
       (this.result/1000).toFixed(2)+ " " + "kg "
    );
  },
    displayResult: function() {
      return (
      "Estimert vekt: " + "\n" + " " + this.result + " " + "gr" + "/" + this.resultKg + "\n" + "  " + this.numberOfUnits + " " + "eksemplar(er)" + "\n " + " " +  this.width + " " + "x" + " "+ this.height + " " + "mm" 
        
      );
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
  
